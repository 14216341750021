
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import BtnCheck from "@/components/scope/btn-check.vue";
import dayjs from "dayjs";
import { priceRulesEl, mobileRulesEl, isPositiveInteger, partsQuantityRules } from "@/utils/verify";
import t from "@common/src/i18n/t";
const parts = namespace("parts");
const user = namespace("user");
const app = namespace("app");
const base = namespace("base");
const supplier = namespace("supplier");
const serviceItem = namespace("service-item");
const marketing = namespace("marketing");

@Component({
  components: { BtnCheck }
})
export default class ServiceEdit extends Vue {
  @app.Mutation setPBPX;
  @supplier.Action getSupplierDetail;
  @parts.Action getPartsList;
  @parts.Action getPartsDetail;
  @serviceItem.Action getServiceList;
  @serviceItem.Action getServiceDetail;
  @marketing.Action queryCardTemplateDetail;
  @marketing.Action createCardTemplate;
  @marketing.Action updateCardTemplate;
  @user.State userData;
  @base.Action getDictionaryList;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  dayjs = dayjs;
  splitThousands = splitThousands;
  get breadData() {
    return [
      { name: t("marketing.card-template"), path: "/marketing/card-template" },
      {
        name: this.isAdd ? t("v210906.new-package-card-template") : t("v210906.modify-package-card-template")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "marketingCardTemplateAdd";
  }
  handleShowCreateSupplier() {
    this.showCreateSupplier = true;
  }
  validateMobile(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      mobileRulesEl(value, callback);
    }
  }
  emptyToLine = emptyToLine;
  groupIndex: number = 1;
  orderFormData: any = {
    cardTemplateName: "", // 模板名称
    cardAmount: "",
    validPeriod: "",
    isPermanent: false, // 是否永久有效
    enableStatus: 1,
    validType: 1,
    cardDesc: "", // 卡说明
    serviceList: [],
    partsList: [],
    cardType: "1", //1：套餐卡、2储蓄卡）
    giftAmount: null, // 赠送金额
    carryingAmount: 0 // 总计账面金额
  };
  definitionServiceData: any = {
    isGroup: true,
    serviceCode: "",
    serviceCount: "",
    workingHours: "",
    serviceFee: "",
    carryingAmount: "",
    partsList: [] // 存放套餐项目的零配件
  };
  definitionPartsData: any = {
    isGroupParts: false, // 是否套餐零配件
    servicePartsQty: 0,
    partsUnit: "", // 单位
    carryingAmount: "", // 账面金额
    amount: "",
    partsCount: "", // 数量
    sellingPrice: "", // 参考销售单价（元）
    partsName: "",
    partsCode: "" // 零配件code
  };
  showCreateSupplier: boolean = false;
  newSupplierData: any = {
    supplierName: "",
    contactName: "",
    contactMobile: ""
  };
  partsList: any = [];
  serviceItemList: any = [];
  orderFormDataRules: any = {
    cardTemplateName: { required: true, message: t("marketing.please-input-card-name"), trigger: "blur" },
    cardAmount: [
      { required: true, message: t("marketing.input-open-card-amount"), trigger: "blur" },
      {
        validator: this.checkPrice,
        trigger: "blur"
      }
    ],
    validPeriod: [
      { required: true, message: t("marketing.input-valid-period"), trigger: "blur" },
      { validator: this.checkPeriod, trigger: "blur" }
    ]
  };
  get orderId() {
    return this.$route.params.id;
  }
  handleConfirmCreateSupplier() {
    (this.$refs.addSupplierForm as any).validate(valid => {});
  }
  checkPrice(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      priceRulesEl(value, callback);
    }
  }
  checkPeriod(rule, value, callback) {
    if (isPositiveInteger(value)) {
      callback();
    } else {
      callback(new Error(t("v210831.please-enter-a-integer")));
    }
  }
  created() {
    this.setPBPX(100);
    this.init();
  }
  async init() {
    await this.getPartsLists();
    await this.getServiceItemList();
    if (!this.isAdd) {
      this.queryCardTemplateDetail({
        cardTemplateCode: this.orderId
      }).then(data => {
        data.data.partsList &&
          data.data.partsList.forEach(item => {
            // 零配件回显
            if (
              !this.partsList.some(partsItem => {
                return partsItem.partsCode === item.partsCode;
              })
            ) {
              this.partsList.push(Object.assign({}, item));
            }
          });
        data.data.serviceList &&
          data.data.serviceList.forEach(item => {
            if (item.partsList && item.partsList.length > 0) {
              const partsIndex = this.groupIndex;
              item.groupServiceIndex = this.groupIndex++;
              item.partsList.forEach(item => {
                let partsData = Object.assign(item, {
                  isGroupParts: true,
                  groupServiceIndex: partsIndex
                });
                data.data.partsList.push(partsData);
              });
            }
            if (
              !this.serviceItemList.some(serviceItem => {
                return serviceItem.serviceCode === item.serviceCode;
              })
            ) {
              this.serviceItemList.push(Object.assign({}, item));
            }
          });
        if (data.data.validPeriod === -1) {
          data.data.isPermanent = true;
          data.data.validPeriod = "180"; // 180为任意正整数,使前端验证通过
        } else {
          data.data.isPermanent = false;
        }
        this.orderFormData = data.data;
      });
    }
  }
  getPartsLists(name = "") {
    return this.getPartsList({
      pageNum: 1,
      pageSize: 20,
      searchKey: name
    }).then(data => {
      this.partsList = this.$safeValue(data, "data.list", []);
    });
  }
  getServiceItemList(name = "") {
    return this.getServiceList({
      pageSize: 20,
      pageNum: 1,
      searchKey: name
    }).then(data => {
      this.serviceItemList = this.$safeValue(data, "data.list", []);
    });
  }
  computedAmount(type, index) {
    if (type === "parts") {
      const currentParts = this.orderFormData.partsList[index];
      const amount = (currentParts.partsCount || 0) * currentParts.sellingPrice;
      currentParts.carryingAmount = amount.toFixed(2);
    } else {
      const currentServiceItem = this.orderFormData.serviceList[index];
      const amount = (currentServiceItem.serviceCount || 0) * currentServiceItem.serviceFee;
      currentServiceItem.partsList.forEach(item => {
        item.partsCount = currentServiceItem.serviceCount * item.servicePartsQty;
      });
      currentServiceItem.carryingAmount = amount.toFixed(2);
    }
    this.computedTotalAmount();
  }
  computedTotalAmount() {
    const partsTotal = this.orderFormData.partsList.reduce((preV, curV) => preV + Number(curV.carryingAmount), 0);
    const serviceTotal = this.orderFormData.serviceList.reduce((preV, curV) => preV + Number(curV.carryingAmount), 0);
    const amount = partsTotal + serviceTotal;
    if (Object.is(NaN, amount)) {
      this.$message.error(t("other.amount-quantity-number-tips") as string);
    }
    this.orderFormData.carryingAmount = amount.toFixed(2);
  }
  searchParts(val) {
    this.getPartsLists(val);
  }
  searchServiceItem(val) {
    this.getServiceItemList(val);
  }
  handleChooseParts(val, index) {
    this.getPartsDetail({
      partsCode: val
    }).then(data => {
      data = data.data || {};
      let partsData = Object.assign({}, this.definitionPartsData, {
        partsCode: val,
        partsUnit: data.partsUnit,
        sellingPrice: data.sellingPrice
      });
      this.orderFormData.partsList.splice(index, 1, partsData);
    });
  }
  handleCheckPermanent(isPermanent) {
    if (isPermanent) {
      this.orderFormData.validPeriod = "180"; // 随便取的值，为了通过大于0的验证
    } else {
      this.orderFormData.validPeriod = "";
    }
    (this.$refs["refCardTamplateInfo"] as any).validateField("validPeriod");
  }
  handleChooseServiceItem(val, index) {
    const haveSameService = this.orderFormData.serviceList.some((item, serviceRowIndex) => {
      return serviceRowIndex !== index && item.serviceCode === val;
    });
    if (haveSameService) {
      this.$message.error(t("marketing.not-choose-repeat-project") as string);
      this.orderFormData.serviceList[index].serviceCode = "";
      return;
    }
    this.getServiceDetail({
      serviceCode: val
    }).then(data => {
      data = data.data || {};
      const serviceItemData = Object.assign({}, this.definitionServiceData, {
        serviceCode: data.serviceCode,
        workingHours: data.workingHours,
        serviceFee: data.serviceFee,
        serviceType: data.serviceType,
        groupServiceIndex: this.groupIndex
      });
      this.orderFormData.serviceList.splice(index, 1, serviceItemData);
      if (data.serviceType === "2" && data.servicePartsList.length > 0) {
        this.orderFormData.serviceList[index].partsList = [];
        data.servicePartsList.forEach(item => {
          let partsData = Object.assign({}, this.definitionPartsData, {
            isGroupParts: true,
            partsName: item.partsName,
            partsUnit: item.partsUnit,
            sellingPrice: item.sellingPrice,
            servicePartsQty: item.servicePartsQty,
            partsCode: item.partsCode,
            partsCount: this.orderFormData.serviceList[index].serviceCount * item.servicePartsQty,
            serviceCode: val,
            groupServiceIndex: this.groupIndex
          });
          this.orderFormData.partsList.push(partsData);
          this.orderFormData.serviceList[index].partsList.push(partsData);
        });
        this.groupIndex++;
      }
      this.clearParts();
    });
  }
  clearParts() {
    // 清除未匹配的套餐零配件
    const selectedServiceGroupIndexArr = this.orderFormData.serviceList.map(item => {
      return item.groupServiceIndex;
    });
    for (let partsIndex = 0; partsIndex < this.orderFormData.partsList.length; partsIndex++) {
      let currentParts = this.orderFormData.partsList[partsIndex];
      if (currentParts.serviceCode && !selectedServiceGroupIndexArr.includes(currentParts.groupServiceIndex)) {
        this.orderFormData.partsList.splice(partsIndex, 1);
        partsIndex--;
      }
    }
  }
  checkAddData(): boolean {
    if (this.orderFormData.serviceList.length < 1 && this.orderFormData.partsList.length < 1) {
      this.$message.error(t("v210831.please-add-a-service-or-parts"));
      return false;
    }
    for (let index = 0; index < this.orderFormData.serviceList.length; index++) {
      const element = this.orderFormData.serviceList[index];
      if (!element.serviceCount) {
        this.$message.error(t("v210831.please-enter-the-serive-number"));
        return false;
      }
    }
    for (let index = 0; index < this.orderFormData.partsList.length; index++) {
      const element = this.orderFormData.partsList[index];
      if (!element.partsCode) {
        this.$message.error(t("setting.select-spare-parts"));
        return false;
      }
      if (!element.partsCount && element.partsCount !== 0) {
        this.$message.error(t("v210831.please-enter-the-number-parts"));
        return false;
      }
      const checkPartsQty = partsQuantityRules(element.partsCount);
      if (!checkPartsQty.success) {
        this.$message.error(checkPartsQty.msg);
        return false;
      }
    }
    return true;
  }
  handleSaveCardTamplate() {
    (this.$refs["refCardTamplateInfo"] as any).validate(valid => {
      if (valid && this.checkAddData()) {
        const func = this.isAdd ? this.createCardTemplate : this.updateCardTemplate;
        const partsListCopy = this.orderFormData.partsList.filter(item => {
          return !item.isGroupParts;
        }); // 深拷贝
        const serviceListCopy = this.orderFormData.serviceList.slice(); // 深拷贝
        const orderFormDataCopy = Object.assign({}, this.orderFormData, {
          partsList: partsListCopy,
          serviceList: serviceListCopy
        });
        // 永久修改-1
        orderFormDataCopy.validPeriod = orderFormDataCopy.isPermanent ? -1 : orderFormDataCopy.validPeriod;
        this.btnStartLoading();
        func(orderFormDataCopy)
          .then(data => {
            this.$message.success(t("base.save-success") as string);
            this.$router.push(`/marketing/card-template-view/${data.data ? data.data.cardTemplateCode : this.orderId}`);
          })
          .finally(data => {
            this.btnStopLoading();
          });
      }
    });
  }
  handleAddParts() {
    this.orderFormData.partsList.push(Object.assign({}, this.definitionPartsData));
  }
  handleAddService() {
    this.orderFormData.serviceList.push(Object.assign({}, this.definitionServiceData));
    this.searchServiceItem("");
  }
  deleteItem(key, index) {
    if (key === "serviceList" && this.orderFormData.serviceList[index].groupServiceIndex) {
      let service = this.orderFormData.serviceList[index];
      for (let partsIndex = 0; partsIndex < this.orderFormData.partsList.length; partsIndex++) {
        let parts = this.orderFormData.partsList[partsIndex];
        if (parts.groupServiceIndex === service.groupServiceIndex) {
          this.orderFormData.partsList.splice(partsIndex, 1);
          partsIndex--;
        }
      }
    }
    this.orderFormData[key].splice(index, 1);
    this.computedTotalAmount();
  }
}
